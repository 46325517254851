<template>
  <partner-withdrawals-table-gql :columns="columns" />
</template>
<script>
import PartnerWithdrawalsTableGql from '@/components/entity/partner-withdrawal-gql/PartnerWithdrawalsTableGql.vue'

export default {
  components: {
    PartnerWithdrawalsTableGql,
  },
  data() {
    return {
      columns: [
        {
          name: 'partnerUser',
          label: 'User',
          filterable: {
            type: 'multientity', queryType: 'users', value: 'id', label: 'email', search: 'email',
          },
        },
        {
          name: 'affKey',
          label: 'Affiliate key',
          filterable: 'like',
        },
        {
          name: 'amount',
          label: 'Profit amount $',
          sortable: true,
        },
        {
          name: 'state',
          label: 'State',
          sortable: false,
          filterable: { type: 'multienum', enum: ['requested', 'investigation', 'investigation - contacted', 'investigation - declined', 'approved by support', 'completed'] },
        },
        {
          name: 'platform',
          label: 'Platform',
          sortable: false,
        },
        {
          name: 'confirmed_at',
          label: 'Confirmed at',
          sortable: true,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'stateAction',
          label: 'State action',
          sortable: false,
        },
      ],
    }
  },
}
</script>
